import React from 'react';

import subfolderIcon from '../../../assets/images/my-library/subfolder.svg';

import ActionsMenu from '../ActionsMenu';
import EditableTitle from '../EditableTitle';
import classes from './styles.module.scss';

const Subfolder = ({
  title,
  onClick,
  isTitleEditable,
  onUpdateTitle,
  actionsMenuProps,
  hasActionsMenu,
  lastPublishedDate,
  icon = subfolderIcon,
  hasUnviewedMeetings,
}) => {
  return (
    <section onClick={onClick} className={classes.Subfolder}>
      <div className={classes.titleContainer}>
        <img src={icon} alt="Folder" />
        <div>
          <EditableTitle
            handleUpdateTitle={onUpdateTitle}
            isEditable={isTitleEditable}
          >
            {title}
          </EditableTitle>
          {hasUnviewedMeetings && (
            <span className={classes.newVideosAdded}>New video(s) added</span>
          )}
          {lastPublishedDate && (
            <span className={classes.publishedDate}>
              modified {lastPublishedDate}
            </span>
          )}
        </div>
      </div>
      <div className={classes.actionsContainer}>
        {hasActionsMenu && <ActionsMenu {...actionsMenuProps} />}
      </div>
    </section>
  );
};

export default Subfolder;
