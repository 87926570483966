import React from 'react';

import { flattenDeep } from 'lodash';

import FolderRow from './FolderRow';
import MeetingRow from './MeetingRow';

const hasContent = (folderId, tree) => {
  const flatTree = flattenDeep(tree);
  return flatTree.some(
    (node) => node.folderId === folderId || node.parentId === folderId
  );
};

export const renderRows = ({
  tree,
  nestLevel = 0,
  showSummaryReportModal,
  isExport,
  expandedFolderIds,
  setExpandedFolderIds,
}) => {
  if (!Array.isArray(tree)) {
    return null;
  }

  return tree.map((node) => {
    //  console.log(node);
    if (Array.isArray(node)) {
      if (
        !expandedFolderIds.includes(node[0]?.id) &&
        !expandedFolderIds.includes(node[0]?.parentId) &&
        !expandedFolderIds.includes(node[0]?.folderId) &&
        !isExport
      ) {
        return null;
      }

      return [
        ...renderRows({
          tree: node,
          nestLevel: nestLevel + 1,
          showSummaryReportModal,
          isExport,
          expandedFolderIds,
          setExpandedFolderIds,
        }),
      ];
    }

    if (node.parentId !== undefined) {
      return (
        <FolderRow
          key={node.id}
          row={node}
          nestLevel={nestLevel}
          showSummaryReportModal={showSummaryReportModal}
          isExport={isExport}
          setExpandedFolderIds={setExpandedFolderIds}
          isExpanded={expandedFolderIds.includes(node.id)}
          hasChildren={Array.isArray(node[0])}
          hasContent={hasContent(node.id, tree)}
        />
      );
    }

    return (
      <MeetingRow
        key={node.id}
        row={node}
        nestLevel={nestLevel}
        showSummaryReportModal={showSummaryReportModal}
        isExport={isExport}
      />
    );
  });
};
