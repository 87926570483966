import React from 'react';

import DotsLoader from '../../../DotsLoader';

import classes from './styles.module.scss';

const Progress = ({ isVisible, step }) => {
  // console.log('Progress', isVisible, percentage, step);

  return (
    <div
      className={classes.Progress}
      style={{ visibility: isVisible ? 'visible' : 'hidden' }}
    >
      <span className={classes.step}>{step}</span>
      <div className={classes.loaderContainer}>
        <DotsLoader pink />
      </div>
    </div>
  );
};

export default Progress;
