import React from 'react';

import Progress from '../../Library/MeetingRow/Progress';

export default function TitleWithProgress({
  classes,
  title,
  isMeetingPublished,
  lastPublishedDate,
  processing,
}) {
  return (
    <div className={classes.titleContainer}>
      <span className={classes.titleText}>{title}</span>
      {isMeetingPublished ? (
        <span className={classes.publishedDate}>
          published {lastPublishedDate}
        </span>
      ) : (
        <Progress
          isVisible={processing}
          percentage={processing?.percentage}
          step={processing?.step}
        />
      )}
    </div>
  );
}
