import { create } from 'zustand';

export const useCreateMeetingStore = create((set) => ({
  prevSectionId: null,
  isSectionAutoScrollDisabled: true,

  setPrevSectionId: (prevSectionId) => set({ prevSectionId }),
  setIsSectionAutoScrollDisabled: (isSectionAutoScrollDisabled) =>
    set({ isSectionAutoScrollDisabled }),
}));
