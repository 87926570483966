/* eslint-disable no-shadow */
/* eslint-disable react/jsx-handler-names */
import React, { createContext, useState, useMemo, useCallback } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ViewerNotesModal from '../modals/ViewerNotesModal';
import MeetingNotesModal from '../modals/MeetingNotesModal';
import ConfirmActionModal from '../modals/ConfirmActionModal';
import ShareMeetingSuccessModal from '../modals/ShareMeetingSuccessModal';

export const UiContext = createContext({
  notifyError: () => {},
  notifySuccess: () => {},
  redirectAfterLogin: '',
  setRedirectAfterLogin: () => {},
  showMeetingNotesModal: () => {},
  showViewerNotesModal: () => {},
});

export default function UiContextProvider({ children }) {
  const [redirectAfterLogin, setRedirectAfterLogin] = useState('');
  const [
    isShareMeetingSuccessModalVisible,
    setIsShareMeetingSuccessModalVisible,
  ] = useState(false);
  const [isConfirmActionModalVisible, setIsConfirmActionModalVisible] =
    useState(false);
  const [confirmActionModalMessage, setConfirmActionModalMessage] =
    useState('');
  const [onConfirm, setOnConfirm] = useState(null);
  const [noConfirmActionModalButtons, setNoConfirmActionModalButtons] =
    useState(false);
  const [confirmActionModalTitle, setConfirmActionModalTitle] = useState('');
  const [isMeetingNotesModalVisible, setIsMeetingNotesModalVisible] =
    useState(false);
  const [isViewerNotesModalVisible, setIsViewerNotesModalVisible] =
    useState(false);
  const [meetingNotes, setMeetingNotes] = useState('');
  const [refetchSections, setRefetchSections] = useState(null);
  const [noteSectionCount, setNoteSectionCount] = useState(0);
  const [noteSectionData, setNoteSectionData] = useState(null);
  const [
    resumeVideoPlaybackAfterNoteDisplay,
    setResumeVideoPlaybackAfterNoteDisplay,
  ] = useState(null);
  const [isSignInHintVisible, setIsSignInHintVisible] = useState(false);
  const [savedPageNumber, setSavedPageNumber] = useState(null);
  const [createSection, setCreateSection] = useState(null);
  const [updateTextSection, setUpdateTextSection] = useState(null);
  const [hidePlaceholder, setHidePlaceholder] = useState(null);

  const notifyError = useCallback(
    (message, options) => toast.error(message, options),
    []
  );

  const notifySuccess = useCallback(
    (message, options) => toast.success(message, options),
    []
  );

  const showShareMeetingSuccessModal = useCallback(() => {
    setIsShareMeetingSuccessModalVisible(true);
  }, []);

  const showViewerNotesModal = useCallback((notes, resume) => {
    setResumeVideoPlaybackAfterNoteDisplay(() => resume);
    setMeetingNotes(notes);
    setIsViewerNotesModalVisible(true);
  }, []);

  const showConfirmActionModal = useCallback(
    ({ message, onConfirm, noButtons, title }) => {
      setOnConfirm(() => onConfirm);
      setConfirmActionModalMessage(message);
      setNoConfirmActionModalButtons(noButtons);
      setConfirmActionModalTitle(title);
      setIsConfirmActionModalVisible(true);
    },
    []
  );

  const showMeetingNotesModal = useCallback(
    ({
      refetchSections,
      noteSectionCount,
      noteSectionData,
      createSection,
      updateTextSection,
      hidePlaceholder,
    }) => {
      setRefetchSections(() => refetchSections);
      setNoteSectionCount(noteSectionCount);
      setNoteSectionData(noteSectionData);
      setIsMeetingNotesModalVisible(true);
      setCreateSection(() => createSection);
      setUpdateTextSection(() => updateTextSection);
      setHidePlaceholder(() => hidePlaceholder);
    },
    []
  );

  const value = useMemo(
    () => ({
      notifyError,
      notifySuccess,
      redirectAfterLogin,
      setRedirectAfterLogin,
      isSignInHintVisible,
      setIsSignInHintVisible,
      showShareMeetingSuccessModal,
      showConfirmActionModal,
      showMeetingNotesModal,
      showViewerNotesModal,
      savedPageNumber,
      setSavedPageNumber,
    }),
    [
      notifyError,
      notifySuccess,
      redirectAfterLogin,
      isSignInHintVisible,
      showShareMeetingSuccessModal,
      showConfirmActionModal,
      showMeetingNotesModal,
      showViewerNotesModal,
      savedPageNumber,
      setSavedPageNumber,
    ]
  );

  return (
    <UiContext.Provider value={value}>
      {children}
      <ToastContainer hideProgressBar />
      <ShareMeetingSuccessModal
        show={isShareMeetingSuccessModalVisible}
        handleClose={() => setIsShareMeetingSuccessModalVisible(false)}
      />
      <ConfirmActionModal
        title={confirmActionModalTitle}
        show={isConfirmActionModalVisible}
        handleClose={() => setIsConfirmActionModalVisible(false)}
        message={confirmActionModalMessage}
        onConfirm={onConfirm}
        noButtons={noConfirmActionModalButtons}
      />
      <MeetingNotesModal
        show={isMeetingNotesModalVisible}
        handleClose={() => {
          setIsMeetingNotesModalVisible(false);
          setNoteSectionData(null);
        }}
        refetchSections={refetchSections}
        noteSectionCount={noteSectionCount}
        noteSectionData={noteSectionData}
        createSection={createSection}
        updateTextSection={updateTextSection}
        hidePlaceholder={hidePlaceholder}
      />
      <ViewerNotesModal
        show={isViewerNotesModalVisible}
        handleClose={() => setIsViewerNotesModalVisible(false)}
        notes={meetingNotes}
        resume={resumeVideoPlaybackAfterNoteDisplay}
      />
    </UiContext.Provider>
  );
}
