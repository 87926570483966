import React, { useState } from 'react';

import { useQuery } from 'react-query';
import classNames from 'classnames';

import ReportHeader from '../ReportHeader';
import UsersTable from '../UsersTable';

import classes from './styles.module.scss';
import ReportService from '../../../services/ReportService';

import { exportReportToPdf } from './helpers';

export default function SummaryReport({
  contentType,
  contentTitle,
  id,
  show,
  isExport,
}) {
  const [isExporting, setIsExporting] = useState(false);

  const { data: report } = useQuery({
    queryKey: ['viewerUserSummaryReport', id],
    queryFn: () =>
      contentType === 'meeting'
        ? ReportService.getViewerUserSummaryMeetingReport(id)
        : ReportService.getViewerUserSummaryFolderReport(id),
    enabled: show && !!id,
  });

  return (
    <div
      className={classNames(classes.SummaryReport, {
        [classes.export]: isExport,
      })}
    >
      <ReportHeader
        reportTitle="Viewer User Summary"
        contentType={contentType}
        contentTitle={contentTitle || report?.[0]?.entityTitle}
        exportReportToPdf={() =>
          exportReportToPdf(
            id,
            contentType === 'meeting' ? 'MeetingSummary' : 'FolderSummary',
            setIsExporting
          )
        }
        isExporting={isExporting}
        isExport={isExport}
      />

      <UsersTable report={report} isExport={isExport} isSingleVideoReport />
    </div>
  );
}
