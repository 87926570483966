import React from 'react';

import classes from './styles.module.scss';

export default function DeleteSectionIcon({ onClick }) {
  return (
    <i className={classes.DeleteSectionIcon} onClick={onClick}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_122_1214)">
          <circle cx="20" cy="18" r="16" fill="white" />
        </g>
        <defs>
          <filter
            id="filter0_d_122_1214"
            x="0"
            y="0"
            width="40"
            height="40"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_122_1214"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_122_1214"
              result="shape"
            />
          </filter>
        </defs>
        <path
          d="M13.6569 11.6674L9.98874 7.99926L13.6569 4.33114C14.206 3.78196 14.206 2.89158 13.6569 2.3424C13.1077 1.79322 12.2173 1.79322 11.6681 2.3424L8 6.01052L4.33188 2.3424C3.7827 1.79322 2.89232 1.79322 2.34315 2.3424C1.79397 2.89158 1.79397 3.78196 2.34315 4.33114L6.01126 7.99926L2.34315 11.6674C1.79397 12.2166 1.79397 13.1069 2.34315 13.6561C2.89232 14.2053 3.7827 14.2053 4.33188 13.6561L8 9.98799L11.6681 13.6561C12.2173 14.2053 13.1077 14.2053 13.6569 13.6561C14.206 13.1069 14.206 12.2166 13.6569 11.6674Z"
          fill="black"
        />
      </svg>
    </i>
  );
}
