export default function videoTimeToHMS(time) {
  if (!Number.isFinite(time)) {
    return '0:00';
  }

  const h = Math.floor(time / 3600);
  const m = Math.floor((time % 3600) / 60);
  const s = Math.floor(time - m * 60);

  let hDisplay = h.toString().length === 1 ? `${h}:` : `${h}:`;
  const mDisplay = m.toString().length === 1 ? `${m}:` : `${m}:`;
  const sDisplay = s.toString().length === 1 ? `0${s}` : `${s}`;

  if (h === 0) {
    hDisplay = '';
  }

  return hDisplay + mDisplay + sDisplay;
}
