import React from 'react';

import classNames from 'classnames';

import playIcon from '../../../assets/images/player-buttons/play.svg';
import pauseIcon from '../../../assets/images/player-buttons/pause.svg';
import stopIcon from '../../../assets/images/player-buttons/stop.svg';
import classes from './styles.module.scss';

export default function PlayerButtons({
  isRecording,
  stopVideoPlayer,
  startTimer,
  startVideoPlayer,
  pauseVideoPlayer,
  createSectionMode,
  isPlaying,
  isVideoReady,
}) {
  // const fileInputRef = useRef();

  const isChrome =
    /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

  const startRecording = () => {
    if (!isRecording) {
      startTimer();
    }
  };

  const stopRecording = () => {
    if (isRecording) {
      stopVideoPlayer();
    }
  };

  return (
    <div
      className={classNames(classes.PlayerButtons, {
        [classes.chrome]: isChrome,
      })}
      style={{
        visibility: isVideoReady || createSectionMode ? 'visible' : 'hidden',
      }}
    >
      <ul>
        {createSectionMode ? (
          <>
            <li>
              <div
                role="button"
                className={classes.recordControl}
                tabIndex={0}
                onClick={startRecording}
              >
                <div className={classes.recordButton} />
                <span>Start Record</span>
              </div>
            </li>
            <li>
              <div
                role="button"
                tabIndex={0}
                onClick={stopRecording}
                className={classes.recordControl}
              >
                <div className={classes.stopButton} />
                <span>Stop Record</span>
              </div>
            </li>
          </>
        ) : (
          <>
            {isPlaying ? (
              <li>
                <button type="button" onClick={pauseVideoPlayer}>
                  <img src={pauseIcon} alt="pause" />
                </button>
              </li>
            ) : (
              <li>
                <button type="button" onClick={startVideoPlayer}>
                  <img src={playIcon} alt="play" />
                </button>
              </li>
            )}
            <li>
              <button type="button" onClick={stopVideoPlayer}>
                <img src={stopIcon} alt="stop" />
              </button>
            </li>
          </>
        )}
      </ul>
    </div>
  );
}
