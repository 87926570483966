import React from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function DotsLoader({ pink }) {
  return (
    <div
      className={classNames(classes.DotsLoader, {
        [classes.pink]: pink,
      })}
    >
      <div />
      <div />
      <div />
    </div>
  );
}
