import React, { useState, useEffect } from 'react';

import { useQuery } from 'react-query';

import classes from './styles.module.scss';
import UserService from '../../../../services/UserService';
import { STATIC_URL } from '../../../../constants/main';
import prolfileIcon from '../../../../assets/images/profile.svg';

let timeout;

export default function UserSearchSuggestion({
  value,
  setValue,
  setIsUserSearchSuggestionVisible,
}) {
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');

  const { data } = useQuery(['suggestedUsers', debouncedSearchQuery], () =>
    UserService.suggestUsers(debouncedSearchQuery)
  );

  useEffect(() => {
    if (!value) {
      return;
    }
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      const lastEmail = value.split(';').pop().trim();
      setDebouncedSearchQuery(lastEmail);
    }, 500);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timeout);
  }, [value]);

  if (!value || !data || value.includes(data.email)) {
    return null;
  }

  return (
    <div
      className={classes.UserSearchSuggestion}
      onClick={() => {
        setValue((prevData) => {
          console.log(prevData);
          const emails = prevData.split(';');

          if (emails.includes(data.email)) {
            return prevData;
          }

          const emailsWithReplace = emails.map((email, index) => {
            if (index === emails.length - 1) {
              return data.email;
            }
            return email.trim();
          });

          return emailsWithReplace.join('; ');
        });

        setIsUserSearchSuggestionVisible(false);
      }}
    >
      <div className={classes.avatarContainer}>
        <img
          src={
            data?.photoPath ? `${STATIC_URL}${data.photoPath}` : prolfileIcon
          }
          alt="User"
        />
      </div>
      <div className={classes.details}>
        <h3 className={classes.title}>{data?.name}</h3>
        <span>{data?.email}</span>
      </div>
    </div>
  );
}
