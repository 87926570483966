import React from 'react';

import classNames from 'classnames';

import SearchBar from '../SearchBar';

import downChevron from '../../assets/images/down-chevron.svg';

export default function Th({
  classes,
  sortField,
  sortOrder,
  setSortField,
  setSortOrder,
  field,
  searchInput,
  setSearchInput,
  title,
}) {
  if (field === 'search') {
    return (
      <th className={classes.searchBarContainer}>
        <SearchBar searchInput={searchInput} setSearchInput={setSearchInput} />
      </th>
    );
  }

  return (
    <th
      className={classNames(classes.sortableTh, {
        [classes.sorted]: sortField === field,
        [classes.sortable]: !!title,
      })}
      onClick={() => {
        if (!title) {
          return;
        }
        setSortField(field);
        setSortOrder((prevState) => {
          return prevState === 'ASC' ? 'DESC' : 'ASC';
        });
      }}
    >
      {title}&nbsp;
      <span
        style={{
          visibility: sortField === field ? 'visible' : 'hidden',
        }}
        className={classNames(classes.sortOrder, {
          [classes.asc]: sortOrder === 'ASC',
          [classes.desc]: sortOrder === 'DESC',
        })}
      >
        <img className={classes.chevron} src={downChevron} alt="Sort order" />
      </span>
    </th>
  );
}
