import { useEffect, useContext } from 'react';

import { SocketContext } from '../context';

const useMeetingReady = (onMeetingReady) => {
  const { socket } = useContext(SocketContext);

  useEffect(() => {
    const executeOnMeetingReady = ({ meetingId }) => {
      onMeetingReady(meetingId);
    };

    socket.on('processingReady', executeOnMeetingReady);

    return () => {
      socket.removeListener('processingReady', executeOnMeetingReady);
    };
  }, [onMeetingReady, socket]);
};

export default useMeetingReady;
