import React from 'react';

import SectionMenu from '../SectionMenu';
import DeleteSectionIcon from '../DeleteSectionIcon';

import classes from './styles.module.scss';

export default function PlaceholderSection({
  onCamera,
  onScreen,
  isScreenEnabled,
  isCameraEnabled,
  isRecording,
  showPlaceholder,
  onQuestion,
  setIsQuestionFormVisible,
  isQuestionFormVisible,
  onAssignSection,
  isAssignSectionFormVisible,
  meeting,
  meetingTitle,
  setIsNoTitleWarningVisible,
  isAudioEnabled,
  onAudio,
  sections,
  createMeeting,
  folderId,
  setMeetingId,
  refetchSections,
  setFile,
  setSectionTitle,
  createDefaultSectionName,
  createSection,
  updateTextSection,
  onDeleteClick,
  prevSectionId,
  hidePlaceholder,
}) {
  return (
    <div className={classes.PlaceholderSection}>
      <div className={classes.sectionContainer}>
        {onDeleteClick && <DeleteSectionIcon onClick={onDeleteClick} />}
        <SectionMenu
          prevSectionId={prevSectionId}
          updateTextSection={updateTextSection}
          createSection={createSection}
          refetchSections={refetchSections}
          showPlaceholder={showPlaceholder}
          isRecording={isRecording}
          isScreenEnabled={isScreenEnabled}
          isCameraEnabled={isCameraEnabled}
          isAudioEnabled={isAudioEnabled}
          onScreen={onScreen}
          onCamera={onCamera}
          onAudio={onAudio}
          onQuestion={onQuestion}
          onAssignSection={onAssignSection}
          setIsQuestionFormVisible={setIsQuestionFormVisible}
          isQuestionFormVisible={isQuestionFormVisible}
          isAssignSectionFormVisible={isAssignSectionFormVisible}
          meeting={meeting}
          meetingTitle={meetingTitle}
          setIsNoTitleWarningVisible={setIsNoTitleWarningVisible}
          sections={sections}
          createMeeting={createMeeting}
          folderId={folderId}
          setMeetingId={setMeetingId}
          setFile={setFile}
          setSectionTitle={setSectionTitle}
          createDefaultSectionName={createDefaultSectionName}
          hidePlaceholder={hidePlaceholder}
        />
      </div>
      <div className={classes.title}>Placeholder</div>
    </div>
  );
}
