import ReportService from '../../services/ReportService';

export const exportReportToPdf = async (id, setIsExporting) => {
  try {
    setIsExporting(true);

    const pdf = await ReportService.getReportPdf({ id, type: 'FolderSummary' });

    const url = window.URL.createObjectURL(new Blob([pdf]));
    const link = document.createElement('a');
    link.href = `data:application/pdf;base64,${pdf}`;
    link.setAttribute('download', 'report.pdf'); // Specify the file name
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error(error);
  } finally {
    setIsExporting(false);
  }
};

export const exportTableToExcel = (currentTab) => {
  const tableIds = {
    Users: 'users-report-table',
    Videos: 'videos-report-table',
  };

  // Get the table HTML
  const table = document.getElementById(tableIds[currentTab]);
  if (!table) {
    return;
  }

  // Remove the last column with actions
  const tableClone = table.cloneNode(true);

  if (currentTab === 'Videos') {
    const tableRows = tableClone.querySelectorAll('tr');
    tableRows.forEach((row) => {
      row.removeChild(row.lastChild);
    });
  }

  const tableHTML = tableClone.outerHTML.replace(/ /g, '%20');

  // Specify the data type
  const dataType = 'application/vnd.ms-excel';

  // Define the file name
  const fileName = `video-report-summary.xls`;

  // Create a download link element
  const downloadLink = document.createElement('a');

  // Browser check to support Microsoft Excel file download
  if (navigator.msSaveOrOpenBlob) {
    const blob = new Blob(['\ufeff', tableHTML], {
      type: dataType,
    });
    navigator.msSaveOrOpenBlob(blob, fileName);
  } else {
    // Create a link to the file
    downloadLink.href = `data:${dataType}, ${tableHTML}`;

    // Setting the file name
    downloadLink.download = fileName;

    // Triggering the function
    downloadLink.click();

    // Remove the link
    downloadLink.remove();
  }
};
