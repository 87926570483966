import React, { useState, useRef, useEffect } from 'react';

import classNames from 'classnames';

import PlaceholderSection from '../PlaceholderSection';
import Tooltip from '../../Tooltip';

import classes from './styles.module.scss';
import whiteCross from '../../../assets/images/create-meeting/whiteCross.svg';

export default function SectionGap({
  onCamera,
  onScreen,
  isScreenEnabled,
  isCameraEnabled,
  isRecording,
  showPlaceholder,
  onQuestion,
  setIsQuestionFormVisible,
  isQuestionFormVisible,
  onAssignSection,
  isAssignSectionFormVisible,
  meeting,
  meetingTitle,
  setIsNoTitleWarningVisible,
  isAudioEnabled,
  onAudio,
  sections,
  createMeeting,
  folderId,
  setMeetingId,
  refetchSections,
  setFile,
  setSectionTitle,
  createDefaultSectionName,
  createSection,
  updateTextSection,
  disabled,
  prevSectionId,
  hidePlaceholder,
  setActivePlaceholderPrevSectionIds,
}) {
  const [isAddButtonVisible, setIsAddButtonVisible] = useState(false);
  const [isPlaceholderSectionVisible, setIsPlaceholderSectionVisible] =
    useState(false);

  const timer = useRef();

  const showAddButtonOnHover = () => {
    timer.current = setTimeout(() => {
      setIsAddButtonVisible(true);
    }, 500);
  };

  // Hide the placeholder after the user selects the option to create a new section.
  // This applies if the 'sections' array contains a section with path === 'placeholder'
  // and its prevSectionId matches the prevSectionId provided in props.
  useEffect(() => {
    if (
      sections.some(
        (section) =>
          section.path === 'placeholder' &&
          section.prevSectionId === prevSectionId
      )
    ) {
      setIsPlaceholderSectionVisible(false);
      setIsAddButtonVisible(false);
    }
  }, [sections, prevSectionId]);

  useEffect(() => {
    if (isPlaceholderSectionVisible) {
      setActivePlaceholderPrevSectionIds((prev) => {
        if (prev.includes(prevSectionId)) {
          return prev;
        }
        return [...prev, prevSectionId];
      });
    } else {
      setActivePlaceholderPrevSectionIds((prev) =>
        prev.filter((id) => id !== prevSectionId)
      );
    }

    return () => {
      setActivePlaceholderPrevSectionIds((prev) =>
        prev.filter((id) => id !== prevSectionId)
      );
    };
  }, [
    isPlaceholderSectionVisible,
    prevSectionId,
    setActivePlaceholderPrevSectionIds,
  ]);

  return isPlaceholderSectionVisible ? (
    <div className={classes.placeholderContainer}>
      <PlaceholderSection
        prevSectionId={prevSectionId}
        onCamera={onCamera}
        onScreen={onScreen}
        isScreenEnabled={isScreenEnabled}
        isCameraEnabled={isCameraEnabled}
        isRecording={isRecording}
        showPlaceholder={showPlaceholder}
        onQuestion={onQuestion}
        setIsQuestionFormVisible={setIsQuestionFormVisible}
        isQuestionFormVisible={isQuestionFormVisible}
        onAssignSection={onAssignSection}
        isAssignSectionFormVisible={isAssignSectionFormVisible}
        meeting={meeting}
        meetingTitle={meetingTitle}
        setIsNoTitleWarningVisible={setIsNoTitleWarningVisible}
        isAudioEnabled={isAudioEnabled}
        onAudio={onAudio}
        sections={sections}
        createMeeting={createMeeting}
        folderId={folderId}
        setMeetingId={setMeetingId}
        refetchSections={refetchSections}
        setFile={setFile}
        setSectionTitle={setSectionTitle}
        createDefaultSectionName={createDefaultSectionName}
        createSection={createSection}
        updateTextSection={updateTextSection}
        onDeleteClick={() => {
          setIsPlaceholderSectionVisible(false);
          setIsAddButtonVisible(false);
          // setPlaceholderSectionCount((prev) => prev - 1);
        }}
        hidePlaceholder={hidePlaceholder}
      />
    </div>
  ) : (
    <div
      className={classNames(classes.SectionGap, {
        [classes.disabled]: disabled,
      })}
      onMouseEnter={showAddButtonOnHover}
      onMouseLeave={() => {
        clearTimeout(timer.current);
        setIsAddButtonVisible(false);
      }}
    >
      {isAddButtonVisible && (
        <div className={classes.addButtonContainer}>
          <Tooltip text="Add Section" width={160} backgroundColor="#e1ce4b">
            <button
              type="button"
              className={classes.addButton}
              onClick={() => {
                setIsPlaceholderSectionVisible(true);
                //  setPlaceholderSectionCount((prev) => prev + 1);
              }}
            >
              <img src={whiteCross} alt="Add section" />
            </button>
          </Tooltip>
        </div>
      )}
    </div>
  );
}
