import React from 'react';
import classNames from 'classnames';

import videoTimeToHMS from '../../../helpers/videoTimeToHMS';
import classes from './styles.module.scss';

export default function VideoProgress({
  isDraggingProgressBar,
  progressBarRef,
  progressBarHandler,
  videoProgress,
  videoRef,
  videoDuration,
  mouseDownHandler,
}) {
  return (
    <div className={classes.VideoProgress}>
      <div
        className={classNames(
          classes.progressBar,
          isDraggingProgressBar && classes.dragging
        )}
        ref={progressBarRef}
        onMouseDown={mouseDownHandler}
        onClick={progressBarHandler}
      >
        <div
          className={classes.progress}
          style={{
            width: `${videoProgress}%`,
          }}
        />
      </div>
      {videoRef.current && (
        <div className={classes.time}>
          {videoTimeToHMS(videoRef.current.currentTime)} /{' '}
          {videoTimeToHMS(videoDuration || 0)}
        </div>
      )}
    </div>
  );
}
