import React from 'react';

import Tab from './Tab';

import classes from './styles.module.scss';

const textLeftCorrections = {
  Overview: [0, 40, 40],
  Videos: [-40, 0, 40],
  Viewers: [0, 0, 0],
};

export default function ReportTabs({ tabs, currentTab, setCurrentTab }) {
  return (
    <div className={classes.ReportTabs}>
      {tabs.map((tab, index) => (
        <Tab
          key={tab}
          tab={tab}
          index={index}
          tabCount={tabs.length}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          textLeft={textLeftCorrections[currentTab][index]}
          lastOne={index === 0}
        />
      ))}
    </div>
  );
}
