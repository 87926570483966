import React from 'react';

import moment from 'moment';

import FilledButton from '../../Buttons/FilledButton';

import classes from './styles.module.scss';
import { getContentTypeIcon } from './helpers';

export default function ReportHeader({
  reportTitle,
  contentType,
  contentTitle,
  folderOwner,
  exportReportToPdf,
  isExport,
  isExporting,
}) {
  return (
    <header className={classes.ReportHeader}>
      <div className={classes.container}>
        <h2 className={classes.title}>{reportTitle}</h2>
        <h1>SnapSOP</h1>
      </div>

      <div className={classes.reportInfo}>
        <div className={classes.infoContainer}>
          <div className={classes.titleContainer}>
            <img src={getContentTypeIcon(contentType)} alt="" />
            <h3 className={classes.contentTitle}>{contentTitle}</h3>
          </div>
          {folderOwner && (
            <div className={classes.contentTitle}>
              Folder Owner: {folderOwner}
            </div>
          )}
          <span className={classes.reportDate}>
            Report date: {moment().format('MMMM DD YYYY')}
          </span>
        </div>
        {!isExport && (
          <div className={classes.buttonContainer}>
            <FilledButton
              color="#f5658c"
              onClick={exportReportToPdf}
              isDisabled={isExporting}
            >
              Export
            </FilledButton>
          </div>
        )}
      </div>
    </header>
  );
}
