import axios from 'axios';

import { hasAuthData } from '../helpers/authStorage';

const MeetingService = {
  async updateMeeting({
    title,
    status,
    notes,
    meetingId,
    comment,
    shouldCreateNewVersion,
  }) {
    const response = await axios.patch(`/meetings/${meetingId}`, {
      title,
      status,
      notes,
      comment,
      shouldCreateNewVersion,
    });

    return response.data;
  },

  async getMeeting(id, meetingVersion) {
    const url = hasAuthData() ? `/meetings/${id}` : `/meetings/public/${id}`;

    const response = await axios.get(url, {
      params: { version: meetingVersion },
    });
    return response.data;
  },

  async getMeetings({
    page,
    search,
    limit,
    type,
    sortField,
    sortOrder,
    deleted,
  }) {
    const response = await axios.get('/meetings', {
      params: {
        limit,
        page,
        search,
        type,
        sortField,
        sortOrder,
        deleted,
      },
    });

    return response.data;
  },

  async getMeetingVersions(id, page, search) {
    const response = await axios.get(`/meetings/${id}/history`, {
      params: {
        page,
        search,
      },
    });

    return response.data;
  },

  async createMeeting({ title, folderId, type }) {
    const response = await axios.post('/meetings', { title, folderId, type });
    return response.data;
  },

  async deleteMeeting(meetingId) {
    const response = await axios.delete(`/meetings/${meetingId}`);
    return response.data;
  },

  async toggleMeetingTrashStatus(meetingId) {
    const response = await axios.post(`/meetings/${meetingId}/trash`);
    return response.data;
  },

  async assignSection({ meetingId, email, message }) {
    const response = await axios.post(`/meetings/${meetingId}/assign-section`, {
      email,
      message,
    });

    return response.data;
  },

  async closeAssignedSection(meetingId) {
    const response = await axios.post(`/meetings/${meetingId}/close-section`);
    return response.data;
  },

  async previewMeeting(meetingId) {
    const response = await axios.post(`/meetings/${meetingId}/preview`);
    return response.data;
  },

  async confirmMeetingView(meetingId) {
    const response = await axios.post(`/meetings/${meetingId}/confirm-view`);
    return response.data;
  },

  async confirmMeetingViewAnonymously({ meetingId, email }) {
    const response = await axios.post(
      `/meetings/${meetingId}/confirm-view/anon`,
      { email }
    );
    return response.data;
  },

  async uploadAudioImage({ image, meetingId }) {
    const form = new FormData();
    form.append('file', image);

    const response = await axios.post(
      `/meetings/${meetingId}/audio-image`,
      form
    );
    return response.data;
  },

  async scheduleMeetingShare({
    meetingId,
    security,
    email,
    activatesAt,
    expiresAt,
    message,
  }) {
    const response = await axios.post(`/meetings/${meetingId}/share/schedule`, {
      security,
      email,
      activatesAt,
      expiresAt,
      message,
    });
    return response.data;
  },
  async shareMeetingLink({ meetingId, security, expiresAt }) {
    const response = await axios.post(`/meetings/${meetingId}/share/link`, {
      security,
      expiresAt,
    });
    return response.data;
  },
  async requestMeetingReview({ meetingId, emails, message }) {
    const response = await axios.post(`/meetings/${meetingId}/request-review`, {
      emails,
      message,
    });
    return response.data;
  },
  async reviewMeeting({ meetingId, reviewId, message, decision, email }) {
    const response = await axios.post(
      `/meetings/${meetingId}/review/${reviewId}`,
      {
        message,
        decision,
        email,
      }
    );
    return response.data;
  },
  async restoreMeetingByVersion(meetingVersionId) {
    const response = await axios.post(
      `/meetings/${meetingVersionId}/version-restore`
    );
    return response.data;
  },
  async getMeetingUsers(id) {
    const response = await axios.get(`/meetings/${id}/users`);
    return response.data;
  },
  async importMeeting({
    sourceMeetingId,
    destinationMeetingId,
    prevSectionId,
  }) {
    const response = await axios.post(
      `/meetings/${destinationMeetingId}/import`,
      {
        sourceMeetingId,
        prevSectionId,
      }
    );
    return response.data;
  },

  async toggleMeetingNotifications(meetingId) {
    const response = await axios.post(
      `/meetings/${meetingId}/toggle-notifications`
    );
    return response.data;
  },
};

export default MeetingService;
