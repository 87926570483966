import React from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function Tab({
  tab,
  index,
  tabCount,
  currentTab,
  setCurrentTab,
  textLeft,
  lastOne,
}) {
  const isActive = tab === currentTab;

  return (
    <button
      className={classNames(classes.Tab, {
        [classes.active]: isActive,
      })}
      onClick={() => setCurrentTab(tab)}
      type="button"
      role="tab"
      style={{
        left: index === 0 ? '' : index * -25,
        zIndex: isActive ? 1000 : tabCount - index,
      }}
    >
      <span
        style={{
          left: textLeft,
        }}
        className={classes.title}
      >
        {tab}
      </span>
      {lastOne ? null : <span className={classes.fakeBorder} />}
    </button>
  );
}
