import React from 'react';

import closeIcon from '../../assets/images/closeIcon.svg';
import classes from './styles.module.scss';

export default function NotAllowedToDeleteSectionMessage({ onHide }) {
  return (
    <div className={classes.NotAllowedToDeleteSectionMessage}>
      <i className={classes.closeIcon} onClick={onHide}>
        <img src={closeIcon} alt="close" />
      </i>
      <div className={classes.content}>
        <p>
          You are not the owner of the video, therefore you cannot delete this
          section.
        </p>
      </div>
    </div>
  );
}
