/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect, useCallback, memo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import backIcon from '../../../assets/images/profile/back.svg';
import WaveForm from '../../MediaContainer/WaveForm';
import VideoProgress from '../VideoProgress';
import classes from './styles.module.scss';

function MeetingVideoPlayer({
  currentAudioSectionWithoutBackground,
  currentAudioSectionProgress,
  meeting,
  waveFormContainerRef,
  videoRef,
  isDraggingProgressBar,
  progressBarRef,
  progressBarHandler,
  setIsDraggingProgressBar,
  videoDuration,
  setPreviousShownQuestions,
  setPreviousShownNote,
  animateProgressBar,
  stopAnimation,
  videoProgress,
  isSectionsVisible,
  setIsSectionsVisible,
  isPlaying,
  setIsPlaying,
  meetingVersion,
  confirmMeetingView,
  folderId,
  title,
  signedS3Url,
}) {
  const [isVideoReady, setIsVideoReady] = useState(false);
  const [isViewConfirmed, setIsViewConfirmed] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const handleBackClick = useCallback(() => {
    const queryParams = new URLSearchParams(location.search);
    const isFromEmail = queryParams.get('fromEmail');

    if (isFromEmail && folderId) {
      navigate(`/my-library/${folderId}`);
    } else if (isFromEmail) {
      navigate('/my-videos');
    } else {
      navigate(-1);
    }
  }, [location.search, navigate, folderId]);

  // Reset last shown question if video has ended
  useEffect(() => {
    if (videoRef.current.currentTime === videoRef.current.duration) {
      setPreviousShownQuestions([]);
      setPreviousShownNote({});
      setIsPlaying(false);
    }
  }, [videoRef.current?.currentTime]);

  // Set video ready state
  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    const setReadyState = () => {
      setIsVideoReady(true);
    };

    videoRef.current.addEventListener('canplay', setReadyState);

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('canplay', setReadyState);
      }
    };
  }, [videoRef]);

  const playButtonHandler = () => {
    setIsPlaying(true);
    videoRef.current.play();
    animateProgressBar();

    if (!isViewConfirmed) {
      confirmMeetingView();
    }

    setIsViewConfirmed(true);
  };

  const rewindButtonHandler = () => {
    videoRef.current.currentTime -= 15;
    animateProgressBar();
  };

  const pauseButtonHandler = () => {
    setIsPlaying(false);
    videoRef.current.pause();
    stopAnimation();
  };

  const forwardButtonHandler = () => {
    videoRef.current.currentTime += 30;
    animateProgressBar();
  };

  return (
    <div className={classes.MeetingVideoPlayer}>
      <header>
        <button
          className={classes.backButton}
          type="button"
          onClick={handleBackClick}
        >
          <img src={backIcon} alt="Back" />
        </button>
        <h1>{meeting ? meeting.title : title}</h1>
      </header>
      {!meetingVersion && (
        <button
          className={classes.sectionsVisibilityButton}
          type="button"
          onClick={() => setIsSectionsVisible((prevState) => !prevState)}
        >
          {isSectionsVisible ? 'Hide sections' : 'Show sections'}
        </button>
      )}
      {currentAudioSectionWithoutBackground && !meetingVersion && (
        <div className={classes.waveFormContainer} ref={waveFormContainerRef}>
          <WaveForm
            source={currentAudioSectionWithoutBackground.section.signedS3Url}
            containerRef={waveFormContainerRef}
            fullHeight
            seek={currentAudioSectionProgress}
          />
        </div>
      )}
      <video
        src={meeting ? meeting.signedS3Url : signedS3Url}
        ref={videoRef}
        style={{ visibility: currentAudioSectionWithoutBackground && 'hidden' }}
      />
      <VideoProgress
        isDraggingProgressBar={isDraggingProgressBar}
        progressBarHandler={progressBarHandler}
        progressBarRef={progressBarRef}
        videoDuration={videoDuration}
        videoProgress={videoProgress}
        videoRef={videoRef}
        mouseDownHandler={() => setIsDraggingProgressBar(true)}
      />
      <div
        className={classes.controlsContainer}
        style={{
          visibility: isVideoReady ? 'visible' : 'hidden',
        }}
      >
        <div className={classes.controls}>
          <div onClick={rewindButtonHandler}>
            <svg
              width="50"
              height="48"
              viewBox="0 0 50 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m 10.059386,34.0993 c 3.361729,5.1614 9.222495,8.5885 15.903771,8.5885 10.434861,0 18.89502,-8.3437 18.89502,-18.6354 0,-10.2864 -8.460159,-18.6302 -18.89502,-18.6302 -8.131906,0 -15.056732,5.0625 -17.7197276,12.1667 H 11.790567 L 5.8980866,25.8753 0.0056061,17.589 3.0550707,17.5889 C 5.9033719,7.75553 15.078296,0.557617 25.963157,0.557617 c 13.161386,0 23.829221,10.520883 23.829221,23.494783 0,12.9844 -10.667835,23.5052 -23.829221,23.5052 -8.735497,0 -16.3697294,-4.6406 -20.5150704,-11.5573 z"
                fill="white"
              />
              <text x="19" y="30" fill="white">
                15
              </text>
            </svg>
          </div>
          {isPlaying ? (
            <div className={classes.pauseButton} onClick={pauseButtonHandler}>
              <div />
              <div />
            </div>
          ) : (
            <div className={classes.playButtonContainer}>
              <div className={classes.playButton} onClick={playButtonHandler} />
            </div>
          )}
          <div onClick={forwardButtonHandler}>
            <svg
              width="50"
              height="48"
              viewBox="0 0 50 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24.0091 42.6878C13.7435 42.6878 5.42057 34.3441 5.42057 24.0524C5.42057 13.766 13.7435 5.4222 24.0091 5.4222C32.0091 5.4222 38.8216 10.4847 41.4414 17.5889H37.9518L43.7487 25.8753L49.5456 17.5889H46.5456C43.7435 7.75553 34.7174 0.557617 24.0091 0.557617C11.0612 0.557617 0.566406 11.0785 0.566406 24.0524C0.566406 37.0368 11.0612 47.5576 24.0091 47.5576C32.6029 47.5576 40.1133 42.917 44.1914 36.0003L39.6549 34.0993C36.3477 39.2607 30.582 42.6878 24.0091 42.6878Z"
                fill="white"
              />
              <text x="15" y="30" fill="white">
                30
              </text>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(MeetingVideoPlayer);
